import React, { useEffect, useState, useRef } from 'react';
import Nav from '../Nav';
import DeliveryInfo from './DeliveryInfo';
import { AnimateAddingGoods } from '../animateAddingGoods';
import { useAppContext } from '../../../contexts/App';
import { useCartContext } from '../../../contexts/CartContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { apiClient } from '../../../libs/api/apiClient';
import { localStorageWrap } from '../../../libs/helpers/localStorageWrap';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAnimationProductsCartContext } from '../../../contexts/AnimationCartContext';
import PhonesPopup from '../popup/PhonesPopup';
import GeomarkIcon from '../../icons/GeomarkIcon';
import TextEllipsis from '../util/TextEllipsis';
import { formatAddress } from '../../../libs/helpers/formatAddress';
import avatarImg from '../../../images/avatar.svg'
import { Preloader } from '../preloader';
import { formatCurrency } from '../../../libs/helpers/formatCurrency';
import { BaseButton } from '../button/BaseButton';
import appStoreImg from '../../../images/appStore.png'
import googlePlayImg from '../../../images/googlePlay.png'
import { OpenHoursPopup, OpenHoursText } from '../popup/other/OpenHours';
import CitiesSelector from '../popup/citites/CitiesSelector';
import AddressSelectorPopup from '../popup/citites/AddressSelector';
import AuthPopup from '../popup/auth/Auth';
import GlobalMessage from '../popup/other/GlobalMessage';
import CartIcon from '../../../images/cartIcon.svg'
import Search from '../../pages/mainPage/Catalog/Search';
import Categories from '../../pages/mainPage/Catalog/Categories';
import { useMetricsContext } from '../../../contexts/MetricsContext';
import moment from 'moment';


export default function Header({searchData, setCatalog, initialCatalog, catalog, searchInHeader, setSearchInHeader, headerRef, activeCategoryRef, activeCategoryIndex}: any) {
  const { company, city, address, branch, openTimeInfo } = useAppContext()
  const { noAddressWarning, setNoAddressWarning, textInAddressAlert, setTextInAddressAlert, cart, headerTextInAddressAlert, synchronize } = useCartContext()
  const { user, updateUser, isLoadingUser } = useAuthContext()
  const { metricsEvent } = useMetricsContext()
  const navigate = useNavigate();
  let location = useLocation();

  const [cityPopupActive, setCityPopupActive] = useState(false)
  const [addressPopupActive, setAddressPopupActive] = useState(false)

  const [showPhones, setShowPhones] = useState(false)
  const wrapperPhones: any = useRef(null);
  const [localUserPhone, setLocalUserPhone] = useState<null | string>(null)

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const wrapperMobileMenu: any = useRef(null);

  const [isDialogOpen, setDialogOpen] = useState<'none'|'auth'|'noConfirm'|'confirm'>('none')

  const [isTimeDialogOpen, setTimeDialogOpen] = useState(false)
  const [openHours, setOpenHours] = useState<any>({isOpen: true})

  useEffect(() => {
    setLocalUserPhone(localStorageWrap.getItem('clientPhone'))
  }, [isDialogOpen])

  useEffect(() => {
    function handleClick(event: any) {
      if (wrapperMobileMenu.current && !wrapperMobileMenu.current.contains(event.target)) {
        setShowMobileMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [showMobileMenu, setShowMobileMenu, wrapperMobileMenu]);

  const [showNav, setShowNav] = useState(false)

  const isMobileScreen = useWindowResize({cvt: (w) => w < 768})

  useEffect(() => {
    if (location.pathname.split('/')?.length !== 2) {
      setShowNav(true)
    } else {
      setShowNav(false)
    }
  }, [location.pathname])

  let [searchParams] = useSearchParams();

  useEffect(()=> {
    let state = searchParams.get('state')
    if (state) {
      localStorageWrap.setItem('tokenVk', state as string)
      setTimeout(() => window.close(), 200)
    }
  }, [searchParams])

  useEffect(() => {
    if (openTimeInfo) {
      setOpenHours(openTimeInfo)
      if (!openTimeInfo.isOpen) {
        if (!localStorageWrap.getItem('timeDialog')) {
          localStorageWrap.setItem('timeDialog', true)
          setTimeDialogOpen(true)
        }
      } else {
        localStorageWrap.setItem('timeDialog', false)
      }
    }
  }, [openTimeInfo])

  useEffect(() => {
    if (noAddressWarning === 'popup') {
      setAddressPopupActive(true)
    }
  }, [noAddressWarning])

  useEffect(() => {
    if (addressPopupActive) setNoAddressWarning(null)
  }, [addressPopupActive])

  const [offsetGlobalMessage, setOffsetGlobalMessage] = useState(0)

  const [isModalFromCart, setIsModalFromCart] = useState(false)
  const [isSynchronizeLoad, setIsSynchronizeLoad] = useState(false)

  const openCart = () => {
    if (isLoadingUser) return;
    setIsModalFromCart(false)
    if (address == null) {
      setTextInAddressAlert('Выберите адрес, чтобы попасть в корзину')
      setNoAddressWarning('toast')
      return
    }

    function goToCart() {
      if (!JSON.parse(localStorageWrap.getItem('isSynchronizedCart') || 'false')) {
        setIsSynchronizeLoad(true)
        synchronize()
        setTimeout(() => {
          navigate(`/${city.slug}/cart`)
          setIsSynchronizeLoad(false)
        }, 1000)
      } else {
        navigate(`/${city.slug}/cart`)
      }
    }

    // юзер с подтвержденным телефоном - переходим в корзину
    if (user && user?.phone_confirmed && user.phone) {
      goToCart()
      return;
    }

    if (branch?.extended?.allowGuestOrderInMobile) {
      // подтверждение необязательно, проверяем есть ли у нас хоть какой то телефон
      // и если нет то просим ввести
      if (localStorageWrap.getItem('clientPhone') || user?.phone) {
        goToCart()
      } else {
        setIsModalFromCart(true)
        setDialogOpen('noConfirm')
      }
    } else {
      // сразу запрашиваем подтверждение телефона, чтобы диалог выглядел сразу как для авторизации
      setIsModalFromCart(true)
      setDialogOpen('confirm')
    }
  }

  const [heightOfHeader, setHeightOfHeader] = useState(0)
  const {animationCartProducts} = useAnimationProductsCartContext()

  useEffect(() => {
    headerRef && setHeightOfHeader(headerRef.current.offsetHeight)
  }, [headerRef])

  return (
    <div style={{ marginBottom: `${offsetGlobalMessage}px` }}>
      <header ref={headerRef} className={`header flex flex-col fixed w-[100vw] z-[110] bg-gray-10 dark:bg-gray-50 shadow-[0px_15px_35px_rgba(0,0,0,0.08)]`}>
        {
          location.pathname?.split('/')?.pop() === city.slug &&
          <div
            onClick={openCart}
            className='md:hidden fixed right-[15px] w-[60px] h-[60px] cursor-pointer rounded-full bg-main'
            style={{bottom: 'calc(2vh)'}}
          >
            { isLoadingUser || isSynchronizeLoad ? <Preloader color={'white'} size={'12px'} className={"absolute gap-x-1 top-6 left-2"}/> :
              <div className="relative h-full w-full flex items-center justify-center">
                {
                  !!cart.raw.filter(p => !p.isGift).length &&
                  <div
                    className='absolute w-[25px] h-[25px] rounded-full top-[-5px] right-[-5px] bg-white text-main text-sm font-bold flex items-center justify-center '>{cart.raw.filter(p => !p.isGift).reduce((count, product) => count + product.count, 0)}</div>
                }
                <img src={CartIcon} alt=""/>
                <div className={`pointer-events-none w-fit h-fit bottom-[-5px] xs:right-[180%] right-[130%] flex flex-col absolute z-[1000]`}>
                  {animationCartProducts.map((item: any, index: any) =>
                    <AnimateAddingGoods key={item.dynamicKey} title={item.title} index={index} id={item.dynamicKey}/>
                  )}
                </div>
              </div>

            }
          </div>
        }

        <div className={``}>
          <div className='container h-[60px] hidden md:flex items-center justify-between'>
            <div className='h-full flex gap-[22px] lg:gap-[30px] items-center'>
              <Link to={`/${city.slug}`} className={`hidden md:block`}>
                <img className={`w-[80px]`} src={company.template.logo} alt=''/>
              </Link>

              {
                branch.contacts.length === 1 ?
                  <div className='flex flex-col xl:flex-row xl:items-center items-start gap-2'>
                    <a className='dark:text-gray-10 lg:text-lg leading-[18px] font-bold' onClick={() => metricsEvent('phone')}>{branch.contacts[0]?.phone}</a>
                    <div className='flex gap-2'>
                      <div className='text-xs opacity-25 dark:text-gray-10 dark:opacity-100'>{branch.contacts[0]?.description}</div>
                      <div onClick={() => {
                        if (Object.values(company.cities).length > 1) {
                          setCityPopupActive(true)
                        }}
                      } className={`text-xs text-main cursor-pointer ${Object.values(company.cities).length > 1 ? '' : '!cursor-default'}`}>{city.title}</div>
                    </div>
                  </div>:
                  <div className='relative' ref={wrapperPhones}>
                    <div className='flex flex-col xl:flex-row xl:items-center items-start gap-2 cursor-pointer' onClick={() => metricsEvent('phone')}>
                      <div className='dark:text-gray-10 lg:text-lg leading-[18px] font-bold' onClick={() => setShowPhones(!showPhones)}>{branch.contacts[0]?.phone}</div>
                      <div className='flex gap-2'>
                        <div className='text-xs opacity-25 dark:text-gray-10 dark:opacity-100' onClick={() => setShowPhones(!showPhones)}>{branch.contacts[0]?.description}</div>
                        <div onClick={() => {
                          if (Object.values(company.cities).length > 1) {
                            setCityPopupActive(true)
                          }}
                        } className={`text-xs text-main cursor-pointer ${Object.values(company.cities).length > 1 ? '' : '!cursor-default'}`}>{city.title}</div>
                      </div>
                    </div>

                    <PhonesPopup
                      className='absolute z-[20] top-[50px]'
                      show={showPhones}
                      setShow={setShowPhones}
                      wrapper={wrapperPhones}
                    />
                  </div>
              }
            </div>

            <div className='flex items-center gap-5'>
              <div onClick={() => setAddressPopupActive(true)}
                   className="relative flex items-center w-[205px] lg:w-[290px] py-2 px-[16px] bg-gray-20 dark:bg-gray-40 rounded-[18px] cursor-pointer">
                <GeomarkIcon/>
                <TextEllipsis className={"ml-2 overflow-hidden text-ellipsis whitespace-nowrap w-full text-center text-gray-50 dark:text-gray-20 text-xs font-medium -tracking-[.01em]"}>
                  {address?.point?.title ? formatAddress(address?.point, ['city']) : 'Выберите адрес'}
                </TextEllipsis>
                <SelectAddressWarning show={noAddressWarning === 'toast'} setNoAddressWarning={setNoAddressWarning} headerText={headerTextInAddressAlert} showText={textInAddressAlert} setShowText={setTextInAddressAlert}/>
              </div>

              <div onClick={() => {
                if (isLoadingUser) return;
                if (user) {
                  navigate(`/${city.slug}/account/profile`)
                } else setDialogOpen('auth')
              }} className="relative h-[30px] w-[30px] rounded-full bg-additional hover:opacity-80 flex items-center justify-center cursor-pointer">
                {/* Кружочек с количеством новых уведомлений */}
                {
                  user?.unreadNotificationsCount &&
                  user?.unreadNotificationsCount > 0 ?
                    <div className={`absolute -top-[3px] -right-[9px] h-[16px] w-[16px] text-[7px] font-bold text-white rounded-full bg-main flex items-center justify-center`}>{user?.unreadNotificationsCount > 99 ? '+99' : user?.unreadNotificationsCount}</div>
                    : <></>
                }
                <img src={avatarImg} alt=''/>

                {isLoadingUser && <Preloader className={'absolute gap-1'} countOfDot={3} size={'5px'}/> }
              </div>
              <div className='relative flex items-center'>
                <div
                  onClick={openCart}
                  className={`flex h-[34px] ${address?.type === 'delivery' && cart?.deliveryZoneId && !!cart.raw?.filter(p => !p.isGift)?.length && !isLoadingUser && !isSynchronizeLoad && 'pr-[36px]'} hover:opacity-80 relative items-center bg-main py-[7px] rounded-[18px] text-white cursor-pointer`}
                >
                  {
                    isLoadingUser || isSynchronizeLoad ?
                      <Preloader countOfDot={3} color={'white'} size={'8px'} className={'min-w-[122px] gap-2'}/> :
                      !!cart.raw?.filter(p => !p.isGift)?.length ?
                      <>
                        <div className="pl-[22px] pr-4 border-r-[1px]">{ cart.raw.filter(p => !p.isGift).reduce((count, product) => count + product.count, 0) }</div>
                        <div className={`flex gap-1 pl-[22px] items-center ${(address?.type === 'delivery' && cart.deliveryZoneId) ? 'pr-[10px]' : 'pr-[22px]'}`}>
                          {formatCurrency(branch.extended.currency.code, JSON.parse(localStorageWrap.getItem('isSynchronizedCart') || 'false') ? cart.total : cart.raw.reduce((total, product) => total + product.total, 0))}
                        </div>
                       
                      </>:
                      <div className='text-white text-sm px-8'>Корзина</div>
                  }
                </div>
                <div style={{top: `${heightOfHeader}px`}} className={`cursor-default ${address?.type === 'pickup'? 'w-[130%]' : 'w-[125%]'} right-0 h-fit flex flex-col absolute z-[1000]`}>
                  {animationCartProducts.map((item: any, index: any) =>
                    <AnimateAddingGoods key={item.dynamicKey} title={item.title} index={index} id={item.dynamicKey}/>
                  )}
                </div>
                {!isLoadingUser && !isSynchronizeLoad && !!cart.raw?.filter(p => !p.isGift)?.length &&
                  <div className='absolute right-0 cursor-pointer'>
                    <DeliveryInfo/>
                  </div>
                }
              </div>

            </div>

          </div>

          {/*-------------------mobile-----------------*/}

          <div className={`container md:hidden relative`} ref={wrapperMobileMenu}>
            <div className='w-full flex items-center justify-between gap-x-4 py-[15px]'>
              <Link to={`/${city.slug}`} className=' lg:hidden block'>
                <img src={company.template.logoThumbnail} alt='' className={"min-w-[33px] max-w-[33px]"}/>
              </Link>

              <div
                onClick={() => {cart.cartId && setAddressPopupActive(true)}}
                className={`relative flex gap-x-2 py-2 px-[16px] w-full items-center bg-gray-20 dark:bg-gray-40 rounded-[18px] cursor-pointer ${!cart.cartId && 'opacity-50 pointer-events-none'}`}>
                <SelectAddressWarning show={noAddressWarning === 'toast'} setNoAddressWarning={setNoAddressWarning}
                  headerText={headerTextInAddressAlert} showText={textInAddressAlert} setShowText={setTextInAddressAlert}/>
                <GeomarkIcon/>
                <TextEllipsis _debug={true}
                              className={"overflow-hidden text-ellipsis dark:text-gray-20 whitespace-nowrap w-[120px] text-center text-gray-50 text-xs font-medium -tracking-[.01em]"}>
                  {address?.point?.title ? formatAddress(address?.point, ['city']) : 'Выберите адрес'}
                </TextEllipsis>
              </div>
              
              <div onClick={() => setShowMobileMenu(!showMobileMenu)} className='w-[18px] h-[18px] flex items-center justify-center cursor-pointer'>
                <div className='relative w-[18px] h-[2px] rounded-full bg-main'>
                  <div className={'absolute top-[-7px] w-[18px] h-[2px] rounded-full bg-main'}></div>
                  {
                    user?.unreadNotificationsCount && user?.unreadNotificationsCount > 0 ?
                    <div className={'absolute top-[-14px] right-[-6px] w-[12px] h-[12px] rounded-full bg-main text-white text-[10px] flex items-center justify-center'}>
                      {user.unreadNotificationsCount > 99 ? '+99' : user.unreadNotificationsCount}
                    </div> :
                    <div className={'absolute top-[-11px] right-[-4px] w-[8px] h-[8px] rounded-full bg-main text-white text-[10px] flex items-center justify-center'}/>
                  }
                  <div className={'absolute bottom-[-7px] w-[18px] h-[2px] rounded-full bg-main'}></div>
                </div>
              </div>
            </div>


            <div className={`shadow-lg absolute left-0 w-full pt-8 pb-[50px] bg-gray-20 dark:bg-gray-50 rounded-b-[16px] ${showMobileMenu ? 'block' : 'hidden'}`}>
              <div className='flex flex-col gap-4 pl-[80px] text-gray-50 dark:text-gray-20'>
                <div className={"flex"}>
                  <Link to={`/${city.slug}/account/notifications`}
                    className={`${location.pathname === `/${city.slug}/account/notifications` && 'text-main'} flex flex-row items-center gap-x-2`}
                    onClick={() => setShowMobileMenu(false)}
                  >
                    Уведомления
                    {user?.unreadNotificationsCount && user?.unreadNotificationsCount > 0 ?
                      <div className={`flex justify-center items-center w-[19px] h-[19px] bg-main rounded-full`}>
                        <p className={"text-white text-[9px]"}>{user.unreadNotificationsCount > 99? '+99' : user.unreadNotificationsCount}</p>
                        {/* если уведомлений будет больше 100 сделать +99, иначе 1000 числа уже не влезают */}
                      </div> : <></>
                    }
                  </Link>
                </div>
                
                <Link to={`/${city.slug}`} className={`${location.pathname === `/${city.slug}` && 'text-main'}`} onClick={()=>setShowMobileMenu(false)}>Наше меню</Link>
                <Link to={`/${city.slug}/stock`} className={`${location.pathname === `/${city.slug}/stock` && 'text-main'}`} onClick={()=>setShowMobileMenu(false)}>Акции</Link>
                <Link to={`/${city.slug}/about`} className={`${location.pathname === `/${city.slug}/about` && 'text-main'}`} onClick={()=>setShowMobileMenu(false)}>О нас</Link>
                <Link to={`/${city.slug}/reviews`} className={`${location.pathname === `/${city.slug}/reviews` && 'text-main'}`} onClick={()=>setShowMobileMenu(false)}>Отзывы</Link>
                <Link to={`/${city.slug}/payment`} className={`${location.pathname === `/${city.slug}/payment` && 'text-main'}`} onClick={()=>setShowMobileMenu(false)}>Доставка и оплата</Link>
              </div>

              <div
                onClick={() => {
                  if (isLoadingUser) return;
                  if (user) {
                    navigate(`/${city.slug}/account/profile`)
                    setShowMobileMenu(false)
                  } else {
                    setDialogOpen('auth')
                    setShowMobileMenu(false)
                  }
                }}
                className={"flex justify-center"}>
                <BaseButton className={"text-white font-bold bg-main my-5 mx-5 w-full"}>Личный кабинет</BaseButton>
              </div>

              <div className='flex items-center mx-[40px] gap-6 mb-[15px]'>
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.67255 3.7661C4.28619 3.7661 3.16211 4.88478 3.16211 6.26447C3.16211 7.64416 4.28619 8.76284 5.67255 8.76284C7.05891 8.76284 8.183 7.64416 8.183 6.26447C8.183 4.88478 7.05891 3.7661 5.67255 3.7661ZM5.67255 7.64416C4.9138 7.64416 4.28619 7.02889 4.28619 6.26447C4.28619 5.50005 4.90444 4.88478 5.67255 4.88478C6.44067 4.88478 7.05891 5.50005 7.05891 6.26447C7.05891 7.02889 6.43131 7.64416 5.67255 7.64416Z" className='fill-gray-50 dark:fill-gray-20'/>
                  <path d="M9.84509 1.86448C9.08633 1.01612 7.70933 0 5.50801 0C1.8922 0 0 2.92722 0 5.81713C0 9.60198 5.19889 15.4471 5.4237 15.6988C5.52676 15.82 5.67662 15.8945 5.83588 15.8945H5.84524C6.00449 15.8945 6.145 15.82 6.25741 15.7081C6.31361 15.6522 7.54075 14.3284 8.7866 12.4732C10.4915 9.93755 11.3532 7.69088 11.3532 5.80778C11.3532 4.37216 10.8006 2.93653 9.84509 1.86448ZM5.85459 14.4589C4.63685 13.0046 1.13344 8.59517 1.13344 5.80782C1.13344 3.5518 2.51044 1.11868 5.51737 1.11868C8.5805 1.11868 10.2292 3.53315 10.2292 5.80782C10.2292 9.08926 7.01613 13.1072 5.85459 14.4589Z" className='fill-gray-50 dark:fill-gray-20'/>
                </svg>

                <div onClick={() => {
                  if (Object.values(company.cities).length > 1) {
                    setCityPopupActive(true)
                  }}
                } className='text-main text-sm'>{ city.title }</div>
              </div>

              <div className='flex items-center mx-[40px] gap-6 mb-[15px]'>
                <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.90031 12.4394C4.71754 12.4394 4.56964 12.5874 4.56964 12.77V13.4314C4.56964 13.614 4.71754 13.7621 4.90031 13.7621H6.88435C7.06713 13.7621 7.21502 13.614 7.21502 13.4314V12.77C7.21502 12.5874 7.06713 12.4394 6.88435 12.4394H4.90031Z" className='fill-gray-50 dark:fill-gray-20'/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.601562 0.939807C0.601562 0.420766 1.02233 0 1.54127 0H10.0781C10.597 0 11.0178 0.420766 11.0178 0.939808V15.3502C11.0178 15.8692 10.597 16.29 10.0781 16.29H1.54127C1.02233 16.29 0.601562 15.8692 0.601562 15.3502V0.939807ZM1.8545 1.25308H9.76482V15.0369H1.8545V1.25308Z" className='fill-gray-50 dark:fill-gray-20'/>
                </svg>


                <div className='text-gray-50 dark:text-gray-20 text-[22px] font-semibold'>
                  <a>{ branch.contacts[0]?.phone }</a>
                </div>
              </div>

              <div className='flex items-center mx-[40px] gap-6'>
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.83636 1.74922C3.70799 1.74922 1.17195 4.28526 1.17195 7.41363C1.17195 10.542 3.70799 13.078 6.83636 13.078C9.96473 13.078 12.5008 10.542 12.5008 7.41363C12.5008 4.28526 9.96473 1.74922 6.83636 1.74922ZM0 7.41363C0 3.63801 3.06074 0.577271 6.83636 0.577271C10.612 0.577271 13.6727 3.63802 13.6727 7.41363C13.6727 11.1893 10.612 14.25 6.83636 14.25C3.06074 14.25 0 11.1893 0 7.41363ZM6.83636 2.92117C7.15999 2.92117 7.42234 3.18352 7.42234 3.50714V7.27239L9.47551 8.47008C9.75505 8.63314 9.84948 8.99195 9.68641 9.27149C9.52335 9.55103 9.16454 9.64545 8.885 9.48238L6.54111 8.11511C6.36109 8.0101 6.25039 7.81737 6.25039 7.60896V3.50714C6.25039 3.18352 6.51274 2.92117 6.83636 2.92117Z" className='fill-gray-50 dark:fill-gray-20'/>
                </svg>

                <div className='flex gap-[10px] items-center text-gray-50 dark:text-gray-20'>
                  <span className={'text-xs'}>Мы работаем:</span>
                  <span className={'text-sm font-semibold'}>
                    {
                      openHours.isOffToday ? 'Выходной' : (
                        (!openHours.nextOpen || !openHours.nextOpen) ? 'Круглосуточно' : `с ${moment(openHours.todayOpenTime).zone(openHours.todayOpenTime).format('HH:mm')} до ${moment(openHours.todayCloseTime).zone(openHours.todayCloseTime).format('HH:mm')}`
                      )
                    }
                  </span>
                </div>
              </div>
              
              {
                (company.androidAppLink || company.iosAppLink) ? 
                <div className='flex flex-row justify-center items-center gap-[20px] mt-[40px]'>
                  {company.iosAppLink &&
                    <Link to={company.iosAppLink} className="bg-[#1C1C1B] px-[13px] w-[135px] h-[52px]  flex items-center justify-center rounded-full">
                      <img src={appStoreImg} className='' alt=''/>
                    </Link>
                  }
                  
                  {company.androidAppLink &&
                    <Link to={company.androidAppLink} className="bg-[#1C1C1B] px-[13px] w-[135px] h-[52px] flex items-center justify-center rounded-full">
                      <img src={googlePlayImg} className='' alt=''/>
                    </Link>
                  }
                </div>
                : <></>
              }
            </div>
          </div>

          {
            !showNav && !showMobileMenu && searchData && catalog &&
            <div className={`container searchBlockInHeader ${!searchInHeader && 'hidden'}`}>
              <div className={`flex items-center relative mt-[5px] pb-[15px]`}>
                <Search 
                  className='absolute z-[100]' 
                  searchData={searchData}
                  setCatalog={setCatalog}
                  initialCatalog={initialCatalog}
                />
                <Categories isSearchInHeader={searchInHeader} catalog={catalog} activeCategoryRef={activeCategoryRef} activeCategoryIndex={activeCategoryIndex}/>
              </div>
            </div>
          }


          {
            showNav &&
            <div className='container '>
              <Nav/>
            </div>
          }
        </div>

        <GlobalMessageComponent setOffsetGlobalMessage={setOffsetGlobalMessage} openHours={openHours} isTimeDialogOpen={isTimeDialogOpen}/>
      </header>

      {
        !openHours.isOpen && isDialogOpen === 'none' && !cityPopupActive && !addressPopupActive &&
        <OpenHoursPopup
          isActive={isTimeDialogOpen}
          close={()=>setTimeDialogOpen(false)}
          openHours={openHours}
        />
      }
      <CitiesSelector
        isActive={cityPopupActive}
        setActive={setCityPopupActive}
      /> 
      <AddressSelectorPopup
        isActive={addressPopupActive}
        setActive={setAddressPopupActive}
      />

      <AuthPopup
        modalFromCart={isModalFromCart && isMobileScreen}
        titleByMode={{'none': '', 'phone': isModalFromCart && isMobileScreen? 'Введите номер телефона' : 'Введите номер для оформления заказа', 'telegram': 'Вход на корзину через Telegram'}}
        startingType={'phone'}
        startingStep={'phoneNumber'}
        phoneConfirmMode={isDialogOpen === 'confirm'? 'always' : 'check'}
        isActive={isDialogOpen === 'confirm' || isDialogOpen === 'noConfirm'}
        close={() => setDialogOpen('none')}
        onSuccess={({ token, user, phone}) => {
          setDialogOpen('none')
          let p = null;
          if (user && token) {
            p = updateUser({...user, token})
          } else if (user) {
            p = updateUser(user);
          } else if (phone) {
            localStorageWrap.setItem('clientPhone', phone)
          }
          Promise.resolve(p).then(() => {
            if (!JSON.parse(localStorageWrap.getItem('isSynchronizedCart') || 'false')) {
              setIsSynchronizeLoad(true)
              synchronize()

              setTimeout(() => {
                navigate(`/${city.slug}/cart`)
                setIsSynchronizeLoad(false)
              }, 1000)
            } else {
              navigate(`/${city.slug}/cart`)
            }
          })
        }}
      />

    </div>
  )
}


export function SelectAddressWarning({show, setNoAddressWarning, showText, headerText}: any) {
  const [hidden, setHidden] = useState(!show);
  const [opaque, setOpaque] = useState(show);
  useEffect(() => {
    if (show) {
      setHidden(false)
    } else {
      let handle = setTimeout(() => {
        setHidden(true)
        setOpaque(false)
      }, 150)
      return () => clearTimeout(handle)
    }
  }, [show])

  useEffect(() => {
    let handle = setTimeout(() => setOpaque(!hidden), 200)
    return () => clearTimeout(handle)
  }, [hidden])

  useEffect(() => {
    if (!show) return
    const handler = () => {
      setNoAddressWarning(null)
    }
    window.addEventListener('mousedown', handler)
    return () => {
      window.removeEventListener('mousedown', handler)
    }
  }, [show])

  return (
    <div className={`${opaque ? 'opacity-1 duration-200 ease-in-out' : 'opacity-0 duration-0'} ${hidden ? 'hidden' : ''}`}>
      <div className={"absolute top-9 right-[25%] z-[50] w-[20px] h-[20px] bg-gray-50 dark:bg-gray-20 rotate-45"}></div>
      <div className={"shadow-md block min-w-[100%] right-0 absolute top-11 sm:right-5 z-[60] sm:min-w-[343px] bg-gray-50 dark:bg-gray-20 rounded-2xl"}>
        <p className=' px-[16px] pt-[8px] text-white dark:text-gray-50 font-bold'>{headerText}</p>
        <p className={"text-[13px] font-medium text-start text-white dark:text-gray-50 p-[16px] pb-[20px]"}>{showText}</p>
      </div>
    </div>
  )

}


function GlobalMessageComponent({ setOffsetGlobalMessage, openHours, isTimeDialogOpen }: any) {
  const { address, branch } = useAppContext()
  const { cart, loading } = useCartContext()

  useEffect(() => {
    if ((address && branch && address.type === 'delivery' && cart?.options?.isCourierUsed === false && cart?.options?.isPickupUsed) || 
      (address && branch && address.type === 'pickup' && cart?.options?.isCourierUsed && cart?.options?.isPickupUsed === false) ||
      (branch && cart?.options?.isCourierUsed === false && !cart?.options?.isPickupUsed) ||
      (!openHours.isOpen && !isTimeDialogOpen) || cart?.options?.isMakingOrderDisabled) {

    } else {
      setOffsetGlobalMessage(0)
    }
  }, [address, branch, openHours, isTimeDialogOpen, cart])

  if (cart?.options?.isMakingOrderDisabled) {
    return (
      <GlobalMessage className={"text-white text-center font-medium text-xs sm:text-lg"} setHeight={setOffsetGlobalMessage}>
        {
          cart.options.disabledMakingOrderComment ?
          cart.options.disabledMakingOrderComment :
          'Приём заказов временно приостановлен.'
        }
      </GlobalMessage>
    )
  }

  if (address && address.type === 'delivery' && cart?.options?.isCourierUsed === false && cart?.options?.isPickupUsed) {
    return (
      <GlobalMessage className={"text-white text-center font-medium text-xs sm:text-lg"} setHeight={setOffsetGlobalMessage}>
        {
          (cart.options.disabledMakingOrderByBranchMessage && cart.options.disabledMakingOrderByBranchMessage !== 'null') ?
          `${ branch.order.ordersDisablingReason }` :
          'Прием заказов на доставку временно приостановлен.'
        }
      </GlobalMessage>
    )
  }

  if (address && address.type === 'pickup' && cart?.options?.isCourierUsed && cart?.options?.isPickupUsed === false) {
    return (
      <GlobalMessage className={"text-white text-center font-medium text-xs sm:text-lg"} setHeight={setOffsetGlobalMessage}>
        {
          (cart?.options?.disabledMakingOrderByBranchMessage && cart?.options?.disabledMakingOrderByBranchMessage !== 'null') ?
          `${ branch.order.ordersDisablingReason }` :
          'Приём заказов на самовывоз временно приостановлен.'
        }
      </GlobalMessage>
    )
  }

  if ((cart?.options?.isCourierUsed === false && !cart?.options?.isPickupUsed)) {
    return (
      <GlobalMessage className={"text-white text-center font-medium text-xs sm:text-lg"} setHeight={setOffsetGlobalMessage}>
        {
          (cart?.options?.disabledMakingOrderByBranchMessage && cart?.options?.disabledMakingOrderByBranchMessage !== 'null') ?
          branch.order.ordersDisablingReason :
          'Приём заказов временно приостановлен.'
        }
      </GlobalMessage>
    )
  }

  if (!openHours.isOpen && !isTimeDialogOpen) {
    return (
      <GlobalMessage className='flex-col xs:flex-row !justify-center' setHeight={setOffsetGlobalMessage}>
        <p className={"text-white text-center font-medium text-xs xs:text-[13px] sm:text-[16px] md:text-lg"}>Сейчас мы закрыты</p>
        <p className={"text-white text-center text-xs xxs:text-[13px] sm:text-[16px] md:text-lg"}><OpenHoursText highlightClassName={'text-white'} prefix={<>Приём заказов откроется</>} openHours={openHours}/> </p>
      </GlobalMessage>
    )
  }

  return <></>
}
